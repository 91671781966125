<template>
  <Panel
    :icon="['fas', 'key']"
    loader-text="Loading API Tokens"
    :loading="loading"
    :show-loading-error="showLoadingError"
    :loading-error-text="loadingErrorText"
  >
    <template v-slot:header>
      <span data-cy="heading">API Tokens</span>
    </template>
    <template v-slot:content>
      <b-button
        variant="success"
        :to="{
          name: 'CreateApiToken'
        }"
        size="sm"
        class="mb-3"
      >
        <font-awesome-icon
          class="mr-2"
          :icon="['fas', 'plus']"
        />
        Create API Token
      </b-button>

      <b-table
        data-cy="table-api-tokens"
        :tbody-tr-attr="{ 'data-cy': 'table-row' }"
        :items="apiTokens"
        :fields="fields"
      >
        <template #cell(status)="row">
          <b-badge :variant="getBadgeVariantForStatus(row.item.isActive)">
            {{ getStatusText(row.item.isActive) }}
          </b-badge>
        </template>

        <template #cell(actions)="row">
          <b-button
            v-if="row.item.isActive"
            size="sm"
            title="Deactivate"
            variant="warning"
            @click="showConfirmDeactivateApiToken(row.item)"
          >
            Deactivate
          </b-button>

          <b-button
            v-if="!row.item.isActive"
            size="sm"
            variant="success"
            title="Activate"
            @click="showConfirmActivateApiToken(row.item)"
          >
            Activate
          </b-button>

          <b-button
            class="ml-3"
            size="sm"
            title="Delete"
            variant="danger"
            @click="showConfirmDeleteApiToken(row.item)"
          >
            Delete
          </b-button>
        </template>
      </b-table>

      <b-modal
        id="confirm-deactivate-modal"
        ref="confirm-deactivate-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        title="Confirm Deactivation"
      >
        <p
          v-if="selectedApiToken"
          class="mb-2"
        >
          Are you sure you want to deactivate <strong>{{ selectedApiToken.name }}</strong>?
        </p>

        <template #modal-footer>
          <b-button
            type="button"
            variant="secondary"
            size="sm"
            :disabled="isLoadingAction"
            @click="$bvModal.hide('confirm-deactivate-modal')"
          >
            Cancel
          </b-button>

          <BaseButtonLoader
            :loading="isLoadingAction"
            class="ml-2"
            type="button"
            :custom-button="false"
            size="sm"
            variant="primary"
            @click="deactivateApiToken"
          >
            Deactivate
          </BaseButtonLoader>
        </template>
      </b-modal>

      <b-modal
        id="confirm-activate-modal"
        ref="confirm-activate-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        title="Confirm Activation"
      >
        <p
          v-if="selectedApiToken"
          class="mb-2"
        >
          Are you sure you want to activate <strong>{{ selectedApiToken.name }}</strong>?
        </p>

        <template #modal-footer>
          <b-button
            type="button"
            variant="secondary"
            size="sm"
            :disabled="isLoadingAction"
            @click="$bvModal.hide('confirm-activate-modal')"
          >
            Cancel
          </b-button>

          <BaseButtonLoader
            :loading="isLoadingAction"
            class="ml-2"
            type="button"
            :custom-button="false"
            size="sm"
            variant="primary"
            @click="activateApiToken"
          >
            Activate
          </BaseButtonLoader>
        </template>
      </b-modal>

      <b-modal
        id="confirm-delete-modal"
        ref="confirm-delete-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        title="Confirm Delete API Token"
      >
        <p
          v-if="selectedApiToken"
          class="mb-2"
        >
          Are you sure you want to delete <strong>{{ selectedApiToken.name }}</strong>?
        </p>

        <template #modal-footer>
          <b-button
            type="button"
            variant="secondary"
            size="sm"
            :disabled="isLoadingAction"
            @click="$bvModal.hide('confirm-delete-modal')"
          >
            Cancel
          </b-button>

          <BaseButtonLoader
            :loading="isLoadingAction"
            class="ml-2"
            type="button"
            :custom-button="false"
            size="sm"
            variant="primary"
            @click="deleteApiToken"
          >
            Delete
          </BaseButtonLoader>
        </template>
      </b-modal>

      <b-modal
        id="login-modal"
        ref="login-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        hide-footer
        title="Please login again to verify it's you"
      >
        <Login
          v-if="showLoginModal"
          show-internally
          @success="handleLoginSuccessful"
        />
      </b-modal>
    </template>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'

import Login from '@/views/Login.vue'
import ApiTokensAPI from '@/api/api-tokens.api'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'ListApiTokens',

  title: 'API Tokens',

  components: {
    Login,
  },

  data() {
    return {
      loading: false,
      showLoadingError: false,
      loadingErrorText: '',
      apiTokens: [],

      isLoadingAction: false,
      
      showLoginModal: false,

      currentAction: '',

      selectedApiToken: null,

      fields: Object.freeze([
        {
          key: 'status',
          sortable: true,
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'actions',
          tdAttr: {
            'data-cy': 'table-column-actions'
          }
        },
      ])
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountId',
      'isUserLoggedInWithinLastXMinutes',
    ]),

    isUserLoggedInWithinLast5Minutes() {
      return this.isUserLoggedInWithinLastXMinutes(5)
    },
  },

  async created () {
    await this.getApiTokens()
  },

  methods: {
    async getApiTokens() {
      try {
        this.loading = true
        const response = await ApiTokensAPI.getAccountApiTokens(this.accountId)

        this.apiTokens = response.data
      } catch {
        this.showLoadingError = true
        this.loadingErrorText = 'Unable to load API tokens'
      } finally {
        this.loading = false
      }
    },

    getBadgeVariantForStatus(isActive) {
      return isActive ? 'success' : 'warning'
    },

    getStatusText(isActive) {
      return isActive ? 'Active' : 'Deactivated'
    },

    showConfirmDeactivateApiToken(apiToken) {
      this.selectedApiToken = apiToken

      if (!this.isUserLoggedInWithinLast5Minutes) {
        this.showLoginForVerificationModal()
        this.currentAction = 'deactivate'
        return
      }

      this.$bvModal.show('confirm-deactivate-modal')
    },

    showConfirmActivateApiToken(apiToken) {
      this.selectedApiToken = apiToken

      if (!this.isUserLoggedInWithinLast5Minutes) {
        this.showLoginForVerificationModal()
        this.currentAction = 'activate'
        return
      }

      this.$bvModal.show('confirm-activate-modal')
    },

    showConfirmDeleteApiToken(apiToken) {
      this.selectedApiToken = apiToken

      if (!this.isUserLoggedInWithinLast5Minutes) {
        this.showLoginForVerificationModal()
        this.currentAction = 'delete'
        return
      }

      this.$bvModal.show('confirm-delete-modal')
    },

    showLoginForVerificationModal() {
      this.showLoginModal = true
      this.$bvModal.show('login-modal')
    },

    handleLoginSuccessful() {
      this.$bvModal.hide('login-modal')

      if (this.currentAction === 'activate') {
        this.$bvModal.show('confirm-activate-modal')
      } else if (this.currentAction === 'deactivate') {
        this.$bvModal.show('confirm-deactivate-modal')
      } else {
        this.$bvModal.show('confirm-delete-modal')
      }
    },

    async deleteApiToken() {
      try {
        this.isLoadingAction = true

        await ApiTokensAPI.deleteApiToken(this.accountId, this.selectedApiToken.id)

        showSuccessMessage('Successfully deleted API token')

        this.$bvModal.hide('confirm-delete-modal')

        this.selectedApiToken = null

        await this.getApiTokens()
      } catch (error) {
        showErrorMessage(error?.response?.data ?? 'Unable to delete API token')
      } finally {
        this.isLoadingAction = false
      }
    },

    async deactivateApiToken() {
      try {
        this.isLoadingAction = true

        await ApiTokensAPI.deactivateApiToken(this.accountId, this.selectedApiToken.id)

        showSuccessMessage('Successfully deactivated API token')

        this.$bvModal.hide('confirm-deactivate-modal')

        this.selectedApiToken = null

        await this.getApiTokens()
      } catch (error) {
        showErrorMessage(error?.response?.data ?? 'Unable to deactivate API token')
      } finally {
        this.isLoadingAction = false
      }
    },

    async activateApiToken() {
      try {
        this.isLoadingAction = true

        await ApiTokensAPI.activateApiToken(this.accountId, this.selectedApiToken.id)

        showSuccessMessage('Successfully activated API token')

        this.$bvModal.hide('confirm-activate-modal')

        this.selectedApiToken = null

        await this.getApiTokens()
      } catch (error) {
        showErrorMessage(error?.response?.data ?? 'Unable to activate API token')
      } finally {
        this.isLoadingAction = false
      }
    },
  },
}
</script>
<style lang="">
  
</style>