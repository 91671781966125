<template>
  <b-sidebar
    id="sidebar"
    :sidebar-class="sidebarClass"
    no-header
    bg-variant="-"
    text-variant="-"
  >
    <b-nav vertical>
      <template v-for="(menu, $idx) in menuOptions">
        <SidebarNavItem
          v-if="!menu.children && (!menu.condition || menu.condition())"
          :key="$idx"
          :route="!!menu.click ? null : menu.route"
          :menu-text="menu.menuText"
          :menu-icon="menu.menuIcon"
          :href="menu.href"
          @click=";(menu.click && menu.click()) || noop()"
        />

        <SidebarNavDropdown
          v-if="menu.children && menu.children.length && (!menu.condition || menu.condition())"
          :key="$idx"
          :menu-text="menu.menuText"
          :menu-icon="menu.menuIcon"
          :children="menu.children"
          :route="menu.route.name"
        />
      </template>
    </b-nav>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import logoutMixin from '@/mixins/logout.mixin'
import SidebarNavDropdown from './SidebarNavDropdown.vue'
import SidebarNavItem from './SidebarNavItem.vue'

export default {
  name: 'TheSidebar',

  components: {
    SidebarNavDropdown,
    SidebarNavItem
  },

  mixins: [logoutMixin],

  data() {
    return {
      menuOptions: [
        {
          menuText: 'Call Logs',
          menuIcon: ['fas', 'tachometer-alt'],
          route: {
            name: 'SellerCallLogs'
          },
          condition: () => this.isSellerAccount
        },
        {
          menuText: 'Call Logs',
          menuIcon: ['fas', 'tachometer-alt'],
          route: {
            name: 'BuyerCallLogs'
          },
          condition: () => this.isBuyerAccount
        },
        {
          menuText: 'Campaigns',
          menuIcon: ['fas', 'chart-line'],
          condition: () => this.isBuyerAccount,
          route: {
            name: 'ListBuyerCampaigns|BuyerCreateCampaign'
          },
          children: [
            {
              subMenuText: 'Campaigns',
              route: {
                name: 'ListBuyerCampaigns'
              },
            },
            {
              subMenuText: 'Create Campaign',
              route: {
                name: 'BuyerCreateCampaign'
              },
            }
          ]
        },
        {
          menuText: 'Offers',
          menuIcon: ['fas', 'chart-line'],
          route: {
            name: 'SellerAvailableOffers'
          },
          condition: () => this.isSellerAccount
        },
        {
          menuText: 'Creatives',
          menuIcon: ['fas', 'photo-video'],
          route: {
            name: 'SellerCreatives'
          },          
          condition: () => this.showCreatives && this.isSellerAccount && this.hasAllowedMedicareCategory
        },
        {
          menuText: 'Creatives',
          menuIcon: ['fas', 'photo-video'],
          route: {
            name: 'ReviewCreatives'
          },          
          condition: () => this.showCreatives && this.isBuyerAccount && this.hasAllowedMedicareCategory
        },

        {
          menuText: 'Users',
          menuIcon: ['fas', 'users'],
          route: {
            name: 'UserManagement'
          },          
        },

        {
          menuText: 'Settings',
          menuIcon: ['fas', 'cog'],
          route: {
            name: 'BuyerAccountBudget|AccountTimezone'
          },
          children: [
            {
              subMenuText: 'Account Budget',
              route: {
                name: 'BuyerAccountBudget'
              },
              condition: () => this.isBuyerAccount
            },

            {
              subMenuText: 'Timezone',
              route: {
                name: 'AccountTimezone'
              }
            },
          ]
        },

        {
          menuText: 'Security',
          menuIcon: ['fas', 'key'],
          route: {
            name: 'TwoFactorAuth|ListApiTokens|CreateApiToken'
          },
          children: [
            {
              subMenuText: 'Multi-Factor Auth',
              route: {
                name: 'TwoFactorAuth'
              },
              'data-cy': 'button-2fa'
            },
            {
              subMenuText: 'API Tokens',
              route: {
                name: 'ListApiTokens'
              },
              'data-cy': 'button-api-tokens'
            },
          ]
        },

        {
          menuText: 'Billing',
          menuIcon: ['fas', 'credit-card'],
          route: {
            name: 'BillingSettings|BankInfo|AddFunds|AddCreditCard|PaymentMethods|MyAccount'
          },
          children: [
            {
              subMenuText: 'My Account',
              route: {
                name: 'MyAccount'
              },
              'data-cy': 'button-my-account',
            },
            {
              subMenuText: 'Payment Methods',
              route: {
                name: 'PaymentMethods'
              },
              'data-cy': 'button-payment-methods',
              condition: () => this.isBuyerAccount && this.accountDetails.billingConfiguration.prePay,
            },
            {
              subMenuText: 'Add Credit Card',
              route: {
                name: 'AddCreditCard'
              },
              'data-cy': 'button-add-credit-card',
              condition: () => this.isBuyerAccount && this.accountDetails.billingConfiguration.prePay,
            },
            {
              subMenuText: 'Add Funds',
              route: {
                name: 'AddFunds'
              },
              'data-cy': 'button-add-funds',
              condition: () => this.isBuyerAccount && this.accountDetails.billingConfiguration.prePay,
            },
            {
              subMenuText: 'Advance Pay',
              route: {
                name: 'AdvancePay'
              },
              'data-cy': 'button-add-funds',
              condition: () => this.isBuyerAccount,
            },
            {
              subMenuText: 'Bank Info',
              route: {
                name: 'BankInfo'
              },
              'data-cy': 'button-bank-info',
              condition: () => this.isSellerAccount,
            },
            {
              subMenuText: 'Settings',
              route: {
                name: 'BillingSettings'
              },
              'data-cy': 'button-billing-settings',
              condition: () => this.isBuyerAccount,
            },
          ]
        },
        {
          menuText: 'Logout',
          menuIcon: ['fas', 'power-off'],
          href: 'javascript:;',
          route: {
            name: 'Logout'
          },
          click: this.logoutAndRedirect
        }
      ]
    }
  },  

  computed: {
    ...mapState(['isSidebarExpanded']),

    ...mapState('globalSettings', ['showCreatives']),

    ...mapGetters('auth', [
      'isBuyerAccount',
      'isSellerAccount',
      'accountDetails',
      'hasAllowedMedicareCategory'
    ]),    

    sidebarClass() {
      let styleClasses = 'd-md-flex'
      return this.isSidebarExpanded
        ? styleClasses
        : `${styleClasses} sidebar--collapsed`
    }
  },

  async created (){
    await this.getShowCreativesSetting()
  },

  methods: {
    ...mapActions('globalSettings', ['getShowCreativesSetting']),
    noop() {},
    
  }
}
</script>

<style lang="scss"></style>
