<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid, untouched }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormInput
          id="login-email"
          v-model.trim="email"
          name="Email"
          data-cy="input-email"
          label="Email Address"
          placeholder="Email Address"
          type="email"
          rules="email"
          required
          width="12"
          @blur="onBlurEmail"
        />

        <BaseFormInput
          id="login-password"
          v-model="password"
          name="Password"
          data-cy="input-password"
          label="Password"
          placeholder="Enter password"
          type="password"
          required
          width="12"
        />

        <div
          v-if="!internal"
          class="d-flex mb-2"
        >
          <BaseFormCheckbox
            id="rememberMe"
            v-model="rememberMe"
            name="rememberMe"
            checked-value="true"
            unchecked-value="false"
          >
            Remember Me
          </BaseFormCheckbox>

          <BaseLink
            data-cy="link-forgot-password"
            to="/forgot-password"
            class="ml-auto custom-link"
          >
            Forgot Password?
          </BaseLink>
        </div>

        <div class="text-center mt-4">
          <b-alert
            data-cy="alert-error"
            :show="!!loginError.errorMessage"
            variant="danger"
          >
            {{ loginError.errorMessage }}
          </b-alert>

          <BaseButtonLoader
            class="mx-2"
            button-class="custom-button--white-hover-grad pulse custom-button--wide"
            type="submit"
            data-cy="button-login"
            :disabled="untouched || invalid"
            :loading="isLoading"
          >
            Login
          </BaseButtonLoader>

          <BaseButtonLoader
            v-if="!internal"
            button-class="custom-button--white-hover-grad pulse custom-button--wide"
            data-cy="button-register"
            type="button"
            @click="redirectToRegister()"
          >
            Apply
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import authViewMixin from '@/mixins/auth-view-mixin'

export default {
  name: 'LoginEmail',

  mixins: [authViewMixin],

  props: {
    /**
     * Will this component be shown internally
     */
    internal: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      email: null,
      password: null,
      rememberMe: false,
      isLoading: false
    }
  },

  computed: {
    ...mapState('auth', [
      'loginError',
      'redirectToAfterLoginPath',
      'userDetails'
    ]),

    ...mapGetters('auth', [
      'isAuthenticated'
    ])
  },

  created () {
    this.setRedirectToPathAfterLogin(this.$route.query.redirect)
  },

  methods: {
    ...mapActions('auth', [
      'login',
      'setRedirectToPathAfterLogin'
    ]),

    ...mapMutations('auth', [
      'SET_LOGIN_ERROR',
      'RESET_LOGIN_ERROR'
    ]),

    onBlurEmail() {
      this.email = this.email?.toLowerCase() || ''
    },

    async onSubmit() {
      this.email = this.email.toLowerCase()
      
      this.RESET_LOGIN_ERROR()
      
      if (this.internal && this.email !== this.userDetails.email) {
        this.SET_LOGIN_ERROR({
          errorMessage: 'The email doesn\'t match with the one you\'re currently logged in with.'
        })
        
        return
      }

      this.isLoading = true

      try {
        await this.login({ 
          email: this.email, 
          password: this.password 
        })

        if (this.isAuthenticated && !this.internal) {
          if (this.redirectToAfterLoginPath) {
            this.$router.push(this.redirectToAfterLoginPath)
          } else {
            this.$router.push({
              name: 'Dashboard'
            })
          }
        }
      } finally {
        this.isLoading = false
      }
    },

    redirectToRegister() {
      this.$router.push('/register')
    }
  },
}
</script>

<style>

</style>