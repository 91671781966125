<template>
  <validation-observer
    ref="formValidation"
    v-slot="{ handleSubmit }"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit(onSubmit)"
    >
      <BaseFormInput
        id="mfaCode"
        v-model="mfaCode"
        data-cy="input-mfa"
        name="OTP"
        label="Authentication Code"
        placeholder="Enter the 6 digit authentication code"
        type="text"
        :description="getDescriptionTextForMFAInput()"
        inputmode="numeric"
        autocomplete="one-time-code"
        required
      />

      <div class="text-center mt-3">
        <b-alert
          data-cy="alert-error"
          :show="!!loginError.errorMessage"
          variant="danger"
        >
          {{ loginError.errorMessage }}
        </b-alert>

        <BaseButtonLoader
          button-class="custom-button--wide custom-button--white-hover-grad pulse"
          type="submit"
          :loading="isLoading"
        >
          Next
        </BaseButtonLoader>

        <BaseButtonLoader
          button-class="custom-button--wide custom-button--white-hover-grad pulse"
          type="button"
          @click="resetLoginStageState"
        >
          Retry Login
        </BaseButtonLoader>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import authViewMixin from '@/mixins/auth-view-mixin'

export default {
  name: 'LoginEnterMFACode',

  mixins: [authViewMixin],

  data() {
    return {
      mfaCode: '',
      isLoading: false,
      isLoadingResendButton: false
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isAuthenticated'
    ]),

    ...mapState('auth', [
      'loginError',
      'loginDetails',
      'redirectToAfterLoginPath'
    ])
  },

  methods: {
    ...mapActions('auth', [
      'submitMFACode',
      'resetLoginStageState'
    ]),

    async onSubmit() {
      try {
        this.isLoading = true

        await this.submitMFACode(this.mfaCode)

        if (this.isAuthenticated) {
          if (this.internal) {
            this.$emit('success')
            return
          }

          if (this.redirectToAfterLoginPath) {
            this.$router.push(this.redirectToAfterLoginPath)
          } else {
            this.$router.push({
              name: 'Dashboard'
            })
          }
        }
      } catch(error) {
        this.$emit('error', error)
      } finally {
        this.isLoading = false
      }
    },


    getDescriptionTextForMFAInput() {
      const mfaTypeToDescriptionMap = {
        SMS_MFA: 'Enter the 6 digit authentication code received on your registered mobile phone',
        SOFTWARE_TOKEN_MFA: 'Enter the 6 digit authentication from the authenticator app'
      }
      return mfaTypeToDescriptionMap[this.loginDetails.currentAuthChallenge]
    }

  }
}
</script>

<style>

</style>