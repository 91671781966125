<template>
  <div id="app">
    <notifications group="default" />

    <component :is="layout">
      <router-view />
    </component>

    
    <b-toast
      id="update-app-toast"
      v-model="updateExists"
      title="An update is available"
      no-auto-hide
      toaster="b-toaster-bottom-right"
      solid
    >
      <p class="mb-2">
        A new version of the app has been released
      </p>
      <b-button
        variant="outline-success"
        size="sm"
        @click="refreshApp"
      >
        Update
      </b-button>
    </b-toast>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>

<script>
import updateAppMixin from './mixins/update-app-mixin'

export default {
  name: 'App',

  mixins: [updateAppMixin],

  computed: {
    layout() {
      return this.$route.matched[0]?.meta?.layout || 'div'
    }
  },
}
</script>
