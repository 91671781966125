import httpClient from './httpClient'

const END_POINT = '/users/api-keys'

const ApiTokensAPI = {

  async getAccountApiTokens(accountId) {
    return await httpClient.get(`${END_POINT}/${accountId}`)
  },

  /**
   * Create API Token for account
   * @param {string} accountId 
   * @param {{ name: string }} apiKeyDetails 
   * @returns 
   */
  async createApiToken(accountId, apiKeyDetails) {
    return await httpClient.put(`${END_POINT}/${accountId}`, apiKeyDetails)
  },

  async deleteApiToken(accountId, apiTokenId) {
    return await httpClient.delete(`${END_POINT}/${accountId}/${apiTokenId}`)
  },

  async deactivateApiToken(accountId, apiTokenId) {
    return await httpClient.post(`${END_POINT}/${accountId}/${apiTokenId}/deactivate`)
  },

  async activateApiToken(accountId, apiTokenId) {
    return await httpClient.post(`${END_POINT}/${accountId}/${apiTokenId}/activate`)
  },
}

export default ApiTokensAPI
