import * as Sentry from '@sentry/vue'
import { Crisp } from 'crisp-sdk-web'
import {
  AvatarPlugin,
  BootstrapVueIcons,
  LayoutPlugin,
  FormPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormSelectPlugin,
  SpinnerPlugin,
  FormRadioPlugin,
  BLink,
  FormCheckboxPlugin,
  AlertPlugin,
  NavbarPlugin,
  SidebarPlugin,
  CollapsePlugin,
  FormFilePlugin,
  TablePlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  BadgePlugin,
  ModalPlugin,
  FormTextareaPlugin,
  CardPlugin,
  FormTimepickerPlugin,
  VBTooltipPlugin,
  PopoverPlugin,
  PaginationPlugin,
  ProgressPlugin,
  ToastPlugin
} from 'bootstrap-vue'
import vMultiselectListbox from 'vue-multiselect-listbox'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLinkedinIn,
  faYoutubeSquare,
  faTwitter,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'

import {
  faBars,
  faStar,
  faCheck,
  faTimes,
  faDotCircle,
  faTachometerAlt,
  faChartLine,
  faCog,
  faCreditCard,
  faPowerOff,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faSignOutAlt,
  faPause,
  faPlay,
  faInfoCircle,
  faWrench,
  faDollarSign,
  faSync,
  faPlusCircle,
  faMinusCircle,
  faPlus,
  faLevelDownAlt,
  faExclamationTriangle,
  faQuestionCircle,
  faEdit,
  faEye,
  faBan,
  faCopy,
  faClock,
  faHandHoldingUsd,
  faComments,
  faPhoneVolume,
  faMoneyCheckAlt,
  faFile,
  faDownload,
  faUpload,
  faPhotoVideo,
  faKey,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Notifications from 'vue-notification'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/vee-validation-rules'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import Vue from 'vue'
import VueHead from 'vue-head'

import startCase from 'lodash.startcase'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import BaseFormInput from '@/components/form/BaseFormInput.vue'
import BaseFormRadio from '@/components/form/BaseFormRadio.vue'
import BaseFormSelect from '@/components/form/BaseFormSelect.vue'
import BaseFormCheckbox from '@/components/form/BaseFormCheckbox.vue'
import BaseButtonLoader from '@/components/BaseButtonLoader.vue'
import ButtonLoader from '@/components/ButtonLoader.vue'
import BaseLink from '@/components/BaseLink.vue'
import BaseFileUpload from '@/components/form/BaseFileUpload.vue'
import BaseFileUploadList from '@/components/form/BaseFileUploadList.vue'
import BaseFormTextArea from '@/components/form/BaseFormTextArea.vue'
import BaseFormDualInputList from '@/components/form/BaseFormDualInputList.vue'
import BaseTimePicker from '@/components/form/BaseTimePicker.vue'
import BaseRangeSelect from '@/components/form/BaseRangeSelect.vue'
import BaseFormSwitch from '@/components/form/BaseFormSwitch.vue'

import BaseButtonGroup from '@/components/form/BaseButtonGroup.vue'
import BaseFormToggle from '@/components/form/BaseFormToggle.vue'
import BaseFormToggleWithInput from '@/components/form/BaseFormToggleWithInput.vue'
import BaseCurrencyInput from '@/components/form/BaseCurrencyInput.vue'

import Loader from '@/components/common/Loader.vue'

import CopyToClipboard from '@/directives/CopyToClipboard'

import titleMixin from './mixins/titleMixin'

import Panel from '@/components/Panel.vue'
import CodeContainer from '@/components/common/CodeContainer.vue'
import { customizeCrispChatbox } from './utils/crispChatUtils'


Vue.use(Notifications)
Vue.use(AvatarPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(LayoutPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(AlertPlugin)
Vue.use(NavbarPlugin)
Vue.use(SidebarPlugin)
Vue.use(CollapsePlugin)
Vue.use(VueHead)
Vue.use(FormFilePlugin)
Vue.use(TablePlugin)
Vue.use(ButtonPlugin)
Vue.use(BadgePlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(CardPlugin)
Vue.use(FormTimepickerPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(PopoverPlugin)
Vue.use(PaginationPlugin, {
  BPagination: {
    pills: true
  }
})
Vue.use(ProgressPlugin)
Vue.use(ToastPlugin)

library.add(
  faLinkedinIn,
  faYoutubeSquare,
  faTwitter,
  faFacebookF,
  faBars,
  faStar,
  faCheck,
  faTimes,
  faDotCircle,
  faTachometerAlt,
  faChartLine,
  faCog,
  faCreditCard,
  faPowerOff,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faSignOutAlt,
  faPause,
  faPlay,
  faInfoCircle,
  faWrench,
  faDollarSign,
  faSync,
  faPlusCircle,
  faMinusCircle,
  faPlus,
  faLevelDownAlt,
  faExclamationTriangle,
  faQuestionCircle,
  faEdit,
  faEye,
  faBan,
  faCopy,
  faClock,
  faHandHoldingUsd,
  faComments,
  faPhoneVolume,
  faMoneyCheckAlt,
  faFile,
  faDownload,
  faUpload,
  faPhotoVideo,
  faKey,
  faUsers
)

Vue.component('b-link', BLink)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('BaseFormInput', BaseFormInput)
Vue.component('BaseFormSelect', BaseFormSelect)
Vue.component('BaseFormRadio', BaseFormRadio)
Vue.component('BaseFormCheckbox', BaseFormCheckbox)
Vue.component('BaseLink', BaseLink)
Vue.component('BaseFileUpload', BaseFileUpload)
Vue.component('BaseFileUploadList', BaseFileUploadList)
Vue.component('BaseFormDualInputList', BaseFormDualInputList)
Vue.component('BaseTimePicker', BaseTimePicker)
Vue.component('BaseRangeSelect', BaseRangeSelect)
Vue.component('BaseFormSwitch', BaseFormSwitch)

Vue.component('BaseButtonGroup', BaseButtonGroup)
Vue.component('BaseFormToggle', BaseFormToggle)
Vue.component('BaseFormToggleWithInput', BaseFormToggleWithInput)
Vue.component('BaseCurrencyInput', BaseCurrencyInput)

Vue.component('BaseFormTextArea', BaseFormTextArea)

Vue.component('Panel', Panel)

Vue.component('BaseButtonLoader', BaseButtonLoader)
Vue.component('ButtonLoader', ButtonLoader)
Vue.component('v-multiselect-listbox', vMultiselectListbox)

Vue.component('v-datepicker', DatePicker)

Vue.component('CodeContainer', CodeContainer)

Vue.component('Loader', Loader)

// Vue.component('Plotly', Plotly)

// Directives - Start
Vue.directive('clipboard', CopyToClipboard)
// Directives - End


Vue.mixin(titleMixin)

Vue.mixin({
  methods: {
    dashIfNotAvailable(fn) {
      try {
        return fn()
      } catch (e) {
        return '-'
      }
    },

    startCase
  }
})

const debug = process.env.NODE_ENV !== 'production'

if (!debug) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn:
      'https://9f349eb5ffea4ab393e9a45cf807f38d@o70049.ingest.sentry.io/5700372',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 50% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: ['Non-Error promise rejection captured']
  })
}

const crispWebsiteId = process.env.VUE_APP_CRISP_WEBSITE_ID

if (crispWebsiteId) {
  Crisp.configure(crispWebsiteId)
  Crisp.setSafeMode(!debug)
  customizeCrispChatbox()
}


Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}

app.$mount('#app')
