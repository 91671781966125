<template>
  <div
    v-b-tooltip.hover.right="'Click to copy'"
    v-clipboard
    :data-copy-text="codeText"
    :data-copy-success="codeCopiedSuccessMessage"
    class="code-container clickable"
    @click="$emit('copied')"
  >
    <a
      class="code-container__copy btn btn-function"
    >
      <font-awesome-icon :icon="['fa', 'copy']" />
    </a>

    <pre
      class="code-container__code"
    ><code>{{ codeText }}</code></pre>
  </div>
</template>

<script>
export default {
  name: 'CodeContainer',

  props: {
    codeText: {
      type: String,
      default: ''
    },

    codeCopiedSuccessMessage: {
      type: String,
      default: 'Copied'
    }
  },
}
</script>

<style lang="scss" scoped>
.code-container {
  position: relative;
}

.code-container__copy {
  position: absolute;
  right: 10px;
}

.code-container__code {
  background: #fff;
  padding: 15px;
}
</style>