<template>
  <Panel>
    <template v-slot:header>
      <span data-cy="heading">Upload New Creative</span>
    </template>
    
    <template v-slot:content>
      <validation-observer
        ref="formValidation"
        v-slot="{ handleSubmit }"
      >
        <b-form
          novalidate
          @submit.stop.prevent="handleSubmit(submitForm)"   
        >
          <BaseFormInput
            id="upload_creative_creative_id"
            v-model="externalId"
            :horizontal="true"
            :small="true"
            name="ID"
            label="Creative ID (non CMS ID)"
            type="text"            
            width="7"
            has-tooltip           
          />
          
          <BaseFormInput
            id="upload_creative_creative_name"
            v-model="name"
            :horizontal="true"
            :small="true"
            name="Name"
            label="Creative Name"
            type="text"            
            width="7"
            has-tooltip
          />          

          <BaseFormInput            
            id="upload_creative_landing_page_url"
            v-model="landingPageUrl"
            :horizontal="true"
            :small="true"
            name="Landing page URL"
            label="Landing page URL"
            type="url"            
            width="7"
            has-tooltip
          />

          <BaseFormSelect
            id="upload_creative_category"
            v-model="categoryId"
            :horizontal="true"            
            :small="true"
            name="Category"
            label="Category"
            text-field="name"
            value-field="id"
            :options="categoryList"
            required     
            width="5"
            width-lg="4"
            width-xl="3"
            has-tooltip           
          />

          <BaseFormInput
            v-if="isMedicare"
            id="upload_creative_cms_id"
            v-model="cmsId"
            :horizontal="true"
            :small="true"
            name="CMS ID"
            label="CMS ID"
            type="text"            
            width="7"
            required
            has-tooltip
          />

          <BaseFormSelect
            id="upload_creative_traffic_source"
            v-model="trafficSourceId"
            :horizontal="true"            
            :small="true"
            name="Traffic source"
            label="Traffic source"
            text-field="name"
            value-field="id"
            :options="trafficSourceList"
            required     
            width="5"
            width-lg="4"
            width-xl="3"
            has-tooltip           
          />

          <BaseFormSelect
            id="upload_creative_country"
            v-model="countryId"            
            :horizontal="true"
            :small="true"
            name="Country"
            label="Country"
            text-field="name"
            value-field="id"
            :options="categoryCountries"
            width="5"
            width-lg="4"
            width-xl="3"
            required
            has-tooltip           
          />

          <BaseFormSelect            
            id="upload_creative_language"
            v-model="languageId"
            :horizontal="true"           
            :small="true"
            name="Language"
            label="Language"
            text-field="name"
            value-field="id"
            :options="categoryLanguages"
            width="5"
            width-lg="4"
            width-xl="3"
            required
            has-tooltip           
          />          

          <BaseFileUploadList
            id="upload_creative_files"
            v-model="files"         
            horizontal
            width="8"
            width-lg="6"
            label="Creative Files"
            add-text="Add file"
            accept=".jpg,.png,.jpeg"
            rules="size:10240"
            has-tooltip
            @fileSelected="uploadNewFile"
            @fileRemoved="fileRemoved"
          />

          <BaseFileUploadList
            v-if="isMedicare"
            id="upload_approval_docs"
            v-model="approvalDocs"         
            horizontal
            width="8"
            width-lg="6"
            label="CMS Approval Documents"
            add-text="Add approval document"
            accept=".jpg,.png,.jpeg"
            rules="size:10240"
            has-tooltip
            @fileSelected="uploadNewApprovalDoc"
            @fileRemoved="approvalDocRemoved"
          />
          
          <BaseButtonLoader
            horizontal
            :loading="isLoading"
            class="mt-1"
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
            content-cols="9"
          >
            Create
          </BaseButtonLoader>
        </b-form>        
      </validation-observer>
    </template>
  </Panel>
</template>
    
<script>
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import formInputMixin from '@/mixins/formInputMixin'
import { creativeFileTypes, MEDICARE_FLAG } from '@/store/modules/creative.store'
    
export default {
  name: 'UploadCreative',
    
  title: 'Upload creative', 
  
  mixins: [formInputMixin],
    
  data() {
    return {
      isLoading: false,
      files:[],
      approvalDocs:[],
      name:'',
      externalId:'',
      cmsId:'',
      landingPageUrl:'',
      categoryId:'',
      trafficSourceId:'',
      countryId:'',
      languageId:'',
      categoryLanguages: [],
      categoryCountries: []
    }
  },  

  computed:{
    ...mapState('creative', ['creativeFiles','creativeApprovalDocs']),
    ...mapGetters('account', ['allowedTrafficSources', 'allowedCategories']), 

    categoryList() {
      const categoryList = [...this.allowedCategories] ?? []
      categoryList.unshift({ name: 'Please select', value: null })
      return categoryList
    },

    trafficSourceList() {
      const trafficSourceList = [...this.allowedTrafficSources] ?? []
      trafficSourceList.unshift({ name: 'Please select', value: null })
      return trafficSourceList
    },

    isMedicare() {
      const category = this.categoryList?.find(c => c.id === this.categoryId)
      return category?.flags?.includes(MEDICARE_FLAG)
    }
  }, 

  watch: {
    categoryId: {
      immediate: true,
      handler(newValue) { 
        const category = this.categoryList?.find(c => c.id === newValue)

        const languages = category?.languages ?? []        
        if (!this.languageId || !languages.find(l => l.id === this.languageId)) {
          this.languageId = ''
        }
        this.categoryLanguages = languages

        const countries = category?.countries ?? []        
        if (!this.countryId || !countries.find(l => l.id === this.countryId)) {
          this.languageId = ''
        }
        this.categoryCountries = countries        
      }
    }
  },
  
  destroyed() {
    this.RESET_CREATIVE_FILES()
    this.RESET_APPROVAL_DOCS()
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNavigation)
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNavigation)
  },
  
  methods:{

    ...mapActions('creative', ['uploadCreativeFile', 'createCreative']),  
    ...mapMutations('creative', ['RESET_CREATIVE_FILES','REMOVE_CREATIVE_FILE','RESET_APPROVAL_DOCS','REMOVE_APPROVAL_DOC']),

    preventNavigation(event) {
      if (this.files.some(file => (file.progress > 0 && file.progress < 100 && !file.uploaded) || file.uploaded) ||
            this.approvalDocs.some(file => (file.progress > 0 && file.progress < 100 && !file.uploaded) || file.uploaded))
      {
        event.preventDefault()
        event.returnValue = ""
      }
    },

    async submitForm(){
      if (!this.files.every(x => x.uploaded && !x.error) ||
        !this.approvalDocs.every(x => x.uploaded && !x.error)) {
        return
      }
      
      this.isLoading = true

      try {
        await this.createCreative({
          creativeFileIds: this.creativeFiles,
          approvalDocIds: this.creativeApprovalDocs,
          externalId: this.externalId,
          name: this.name,
          categoryId: this.categoryId,
          trafficSourceId: this.trafficSourceId,
          countryCode: this.countryId,
          languageCode: this.languageId,
          cmsId: this.isMedicare ? this.cmsId : '',
          landingPageUrl: this.landingPageUrl
        })

        showSuccessMessage('Successfully submitted creative')   
        
        this.$router.push({ name: 'SellerCreatives' })
      }
      catch(error) {
        showErrorMessage(error.response?.data?.title)
      }
      finally{
        this.isLoading = false
      }
    },

    async uploadNewFile(event){
      if (!event || !event.list) {
        return
      }     

      try {
        const responseData = await this.uploadCreativeFile({
          request: {
            file: event.list[event.index].file,
            fileType: creativeFileTypes.Image, 
            index: event.index            
          }, 
          progressCallback: this.fileUploadSuccess,
          isApprovalDoc: false
        })
        this.files[event.index].fileId = responseData        
        this.files[event.index].uploaded = true
        this.files[event.index].progress = 0
        this.files[event.index].error = ''

        showSuccessMessage('Successfully uploaded ' + this.files[event.index].fileName)        
      }
      catch(error){
        this.files[event.index].error = error
        showErrorMessage(error)
      }
      finally{
        //        
      }
    },

    async uploadNewApprovalDoc(event){
      if (!event || !event.list) {
        return
      }     

      try {
        const responseData = await this.uploadCreativeFile({
          request: {
            file: event.list[event.index].file,
            fileType: creativeFileTypes.Image, 
            index: event.index            
          }, 
          progressCallback: this.approvalDocUploadSuccess,
          isApprovalDoc: true
        })
        this.approvalDocs[event.index].fileId = responseData        
        this.approvalDocs[event.index].uploaded = true
        this.approvalDocs[event.index].progress = 0
        this.approvalDocs[event.index].error = ''

        showSuccessMessage('Successfully uploaded ' + this.approvalDocs[event.index].fileName)        
      }
      catch(error){
        this.approvalDocs[event.index].error = error
        showErrorMessage(error)
      }
      finally{
        //        
      }
    },

    fileRemoved(fileId) {
      this.REMOVE_CREATIVE_FILE(fileId)
    },

    approvalDocRemoved(fileId) {
      this.REMOVE_APPROVAL_DOC(fileId)
    },

    fileUploadSuccess(value) {
      this.files[value.index].progress = parseInt(Math.round( value.progress ))      
    },
    
    approvalDocUploadSuccess(value) {
      this.approvalDocs[value.index].progress = parseInt(Math.round( value.progress ))      
    }
  },
    
}
</script>
    
    <style lang="scss" scoped>
      
    </style>