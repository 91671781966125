<template>
  <div>
    <Panel
      :icon="['fas', 'chart-line']"
      loader-text="Loading Campaigns"
      :loading="loading"
      :show-loading-error="showLoadingError"
      :loading-error-text="loadingErrorText"
    >
      <template v-slot:header>
        <span data-cy="heading">Campaigns</span>
      </template>
      <template v-slot:content>
        <b-table
          data-cy="table-buyer-campaigns"
          :tbody-tr-attr="{ 'data-cy': 'table-row' }"
          :items="buyerCampaigns"
          :fields="fields"
        >
          <template #cell(status)="row">
            <b-badge :variant="getBadgeVariantForCampaignStatus(row.item)">
              {{ getStatusText(row.item) }}
            </b-badge>
          </template>

          <template #cell(maxBidPerCallInUSD)="row">
            {{ getMaxBidPerCall(row.item) }}
          </template>

          <template #cell(dailyBudgetInUSD)="row">
            {{ formatCurrency(row.item.budget.dailyBudgetInUSD) }}
          </template>

          <template #cell(actions)="row">
            <b-button
              :to="{
                name: 'BuyerEditCampaign',
                params: { id: row.item.id }
              }"
              data-cy="button-edit"
              class="bg-transparent border-0 p-0"
              size="sm"
            >
              <font-awesome-icon
                :icon="['fa', 'edit']"
              />
            </b-button>
            <b-button
              v-if="!isCampaignPaused(row.item)"
              v-b-tooltip.hover.left
              class="bg-transparent border-0 p-0 ml-3"
              size="sm"
              title="Pause Campaign"
              @click="showConfirmPauseBuyerCampaign(row.item)"
            >
              <font-awesome-icon
                :icon="['fa', 'pause']"
              />
            </b-button>
            <b-button
              v-if="isCampaignPaused(row.item)"
              v-b-tooltip.hover.left
              class="bg-transparent border-0 p-0 ml-3"
              size="sm"
              title="Resume Campaign"
              @click="showConfirmResumeBuyerCampaign(row.item)"
            >
              <font-awesome-icon
                :icon="['fa', 'play']"
              />
            </b-button>
          </template>
        </b-table>
      </template>
    </Panel>

    <b-modal
      id="confirm-pause-modal"
      ref="confirm-pause-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Pause"
      ok-title="Pause"
      button-size="sm"
      @ok="pauseBuyerCampaign"
    >
      <p
        v-if="selectedBuyerCampaign"
        class="mb-2"
      >
        Are you sure you want to pause <strong>{{ selectedBuyerCampaign.name }}</strong>?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('confirm-pause-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="pauseBuyerCampaign"
        >
          Pause
        </BaseButtonLoader>
      </template>
    </b-modal>

    <b-modal
      id="confirm-resume-modal"
      ref="confirm-resume-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Resume"
      ok-title="Resume"
      button-size="sm"
      @ok="resumeBuyerCampaign"
    >
      <p
        v-if="selectedBuyerCampaign"
        class="mb-2"
      >
        Are you sure you want to resume <strong>{{ selectedBuyerCampaign.name }}</strong>?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('confirm-resume-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="resumeBuyerCampaign"
        >
          Resume
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import formatCurrency from '@/utils/formatCurrency'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { getBadgeVariantForCampaignStatus, getStatusText, PAUSED_STATUS, campaignRoutingTypes } from '@/store/modules/campaign.store'

export default {
  name: 'ListBuyerCampaigns',

  title: 'Campaigns',

  data() {
    return {
      loading: true,
      showLoadingError: false,
      loadingErrorText: '',
      selectedBuyerCampaign: null,
      isLoading: false,
      fields: Object.freeze([
        {
          key: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'category.name',
          label: 'Category',
          sortable: true,
        },
        {
          key: 'country.name',
          label: 'Country',
          sortable: true,
        },
        {
          key: 'language.name',
          label: 'Language',
          sortable: true,
        },
        {
          key: 'routing.type',
          label: 'Routing',
          sortable: true,
          formatter: value => {
            return this.startCase(value)
          }
        },
        {
          key: 'trafficSources.callType',
          label: 'Call Type',
          sortable: true
        },
        {
          key: 'maxBidPerCallInUSD',
          label: 'Bid',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'dailyBudgetInUSD',
          label: 'Daily Budget',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          tdAttr: {
            'data-cy': 'table-column-actions'
          }
        }
      ])
    }
  },

  computed: {
    ...mapState('campaign', [
      'buyerCampaigns'
    ])
  },

  async created () {
    try {
      this.loading = true
      await this.fetchBuyerCampaigns()
    } catch (error) {
      if (error.response.status === 422) {
        this.showLoadingError = true
        this.loadingErrorText = error.response.data.title
      } else {
        showErrorMessage(error.response?.data?.title)
      }
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions('campaign', [
      'fetchBuyerCampaigns'
    ]),

    ...mapActions('campaign', {
      setResumeBuyerCampaignStatus: 'resumeBuyerCampaign',
      setPauseBuyerCampaignStatus: 'pauseBuyerCampaign'
    }),

    getBadgeVariantForCampaignStatus,

    getStatusText,

    formatCurrency,

    isCampaignPaused(campaign) {
      return campaign.status === PAUSED_STATUS || campaign.isPaused
    },

    showConfirmPauseBuyerCampaign(campaign) {
      this.selectedBuyerCampaign = campaign

      this.$bvModal.show('confirm-pause-modal')
    },

    async pauseBuyerCampaign() {
      try {
        this.isLoading = true
        await this.setPauseBuyerCampaignStatus({ campaignId: this.selectedBuyerCampaign.id })

        showSuccessMessage('Successfully paused buyer campaign')

        this.selectedBuyerCampaign = null

        this.$bvModal.hide('confirm-pause-modal')
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.isLoading = false
      }
    },

    showConfirmResumeBuyerCampaign(campaign) {
      this.selectedBuyerCampaign = campaign

      this.$bvModal.show('confirm-resume-modal')
    },

    async resumeBuyerCampaign() {
      try {
        this.isLoading = true
        await this.setResumeBuyerCampaignStatus({ campaignId: this.selectedBuyerCampaign.id, status: this.selectedBuyerCampaign.status })

        showSuccessMessage('Successfully resumed buyer campaign')

        this.selectedBuyerCampaign = null

        this.$bvModal.hide('confirm-resume-modal')
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.isLoading = false
      }
    },

    getMaxBidPerCall(campaign) {
      return campaign.routing.type === campaignRoutingTypes.Static ? this.formatCurrency(campaign.budget.maxBidPerCallInUSD) : 'Dynamic'
    }
  },
}
</script>

<style>

</style>