<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Destination</span>
    </template>

    <template v-slot:content>
      <div>
        <BaseButtonGroup
          id="buyer-create-campaign__destination__routing"
          :readonly="isProgrammaticBuyerCampaign"
          horizontal
          internal-platform-input
          data-cy="button-group-routing"
          :selected-option="routingType"
          :click-handler="updateRoutingType"
          label="Routing"
          :options="routingOptions"
          has-tooltip
        />

        <fieldset v-if="routingType !== 'Programmatic'">
          <legend class="legend-title">
            Basic Details
          </legend>

          <TargetDestinationForm
            v-if="showTargetDestinationForm"
            :target-details="buyerCampaign.routing"
            @update="updateRouting"
          />

          <RTBDestinationForm
            v-if="routingType === 'RingbaRTB'"
            :target-details="buyerCampaign.routing"
            @update="updateRouting"
          />
        </fieldset>

        <ProgrammaticTargetForm
          v-if="routingType === 'Programmatic'"
          :programmatic-target="programmaticTarget"
          :readonly="readonly"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'


import TargetDestinationForm from '../Target/TargetDestinationForm.vue'
import ProgrammaticTargetForm from '../ProgrammaticTarget/ProgrammaticTargetForm.vue'
import RTBDestinationForm from './RTBDestinationForm.vue'

const defaultRoutingOptions = [
  {
    text: 'Static',
    value: 'Static'
  }, 
  {
    text: 'Ringba RTB',
    value: 'RingbaRTB'
  }
]

export default {
  name: 'Destination',

  components: {
    ProgrammaticTargetForm,
    TargetDestinationForm,
    RTBDestinationForm
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ...mapState('campaign', ['buyerCampaign']),

    ...mapGetters('campaign', ['isProgrammaticBuyerCampaign']),

    routingOptions() {
      return this.isProgrammaticBuyerCampaign ? Object.freeze([
        ...defaultRoutingOptions,
        {
          text: 'Programmatic',
          value: 'Programmatic'
        }
      ]) : Object.freeze([
        ...defaultRoutingOptions
      ])
    },

    showTargetDestinationForm() {
      return this.routingType === 'Static'
    },

    routingType: {
      get() {
        return this.buyerCampaign.routing.type
      },

      set(value) {
        this.updateRouting({...this.buyerCampaign.routing, type: value })
      }
    },

    isNumberDynamic: {
      get() {
        return this.buyerCampaign.routing.programmaticTarget.isNumberDynamic
      },

      set(value) {
        this.updateRouting({
          ...this.buyerCampaign.routing,
          programmaticTarget: {
            ...this.programmaticTarget,
            isNumberDynamic: value
          }
        })
      }
    },

    programmaticTarget() {
      return this.buyerCampaign.routing.programmaticTarget
    }
  },

  methods: {
    ...mapMutations('campaign', [
      'UPDATE_BUYER_CAMPAIGN_ROUTING', 
      'RESET_BUYER_CAMPAIGN_MINIMUM_DURATION',
    ]),

    updateRoutingType(option) {
      this.routingType = option
    },

    updateRouting(routing) {
      this.UPDATE_BUYER_CAMPAIGN_ROUTING(routing)
      
      if (routing.type === 'RingbaRTB') {
        this.RESET_BUYER_CAMPAIGN_MINIMUM_DURATION()
      }
    },

  }
}
</script>

<style lang="scss" scoped></style>
