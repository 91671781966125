<template>
  <b-container
    class="mx-sm-auto login__wrapper"
    :class="{'login__wrapper-internal': showInternally}"
  >
    <div
      v-if="!showInternally"
      class="w-50 mx-auto px-3 mb-2"
    >
      <RingbaXLogoDark />
    </div>

    <transition
      name="component-fade"
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="loginStepComponent"
          :internal="showInternally"
          @submit="onSubmit"
          @success="$emit('success')"
          @error="$emit('error')"
        />
      </keep-alive>
    </transition>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import RingbaXLogoDark from '@/assets/img/ringbax-logo-dark-theme.svg'
import LoginEmail from '@/components/login/LoginEmail.vue'
import LoginChooseMFAType from '@/components/login/LoginChooseMFAType.vue'
import LoginEnterMFACode from '@/components/login/LoginEnterMFACode.vue'
import LoginEnterPermanentPassword from '@/components/login/LoginEnterPermanentPassword.vue'
import { MFA_STAGES } from '@/store/modules/auth.store'
import LoginSuccess from '@/components/login/LoginSuccess.vue'

const stageComponents = {
  [MFA_STAGES.ENTER_USER_CREDS]: LoginEmail,
  [MFA_STAGES.SELECT_MFA_TYPE]: LoginChooseMFAType,
  [MFA_STAGES.SMS_MFA]: LoginEnterMFACode,
  [MFA_STAGES.SOFTWARE_TOKEN_MFA]: LoginEnterMFACode,
  [MFA_STAGES.NEW_PASSWORD_REQUIRED]: LoginEnterPermanentPassword,
  [MFA_STAGES.LOGIN_SUCCESS]: LoginSuccess
}

export default {
  name: 'Login',

  title: 'Ringba X',

  components: {
    RingbaXLogoDark,
    LoginEmail,
    LoginChooseMFAType,
    LoginEnterMFACode
  },

  props: {
    /**
     * Will this component be shown internally
     */
    showInternally: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ...mapGetters('auth', [
      'currentAuthChallenge'
    ]),

    loginStepComponent() {
      return stageComponents[this.currentAuthChallenge]
    },
  },

  created () {
    this.SET_LOGIN_INTERNAL(this.showInternally)

    this.resetLoginStageState()

    if (this.showInternally) {
      return
    }

    this.logout()
  },

  methods: {
    ...mapActions('auth', [
      'resetLoginStageState',
      'logout'
    ]),

    ...mapMutations('auth', [
      'RESET_LOGIN_STAGE_STATE',
      'SET_LOGIN_INTERNAL'
    ]),

    onSubmit() {},
  },
}
</script>

<style lang="scss" scoped>
.login__wrapper {
  min-height: 400px;
}

.login__wrapper-internal {
  min-height: auto;
}

@include media-breakpoint-up(sm) {
  .login__wrapper {
    width: 467px;
  }
}
</style>