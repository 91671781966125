<template>
  <Panel :icon="['fas', 'key']">
    <template v-slot:header>
      <span data-cy="heading">Create API Token</span>
    </template>

    <template v-slot:content>
      <validation-observer
        ref="formValidation"
        v-slot="{ handleSubmit }"
      >
        <b-form
          novalidate
          @submit.stop.prevent="handleSubmit(submitForm)"
        >
          <BaseFormInput
            id="api-token-name"
            v-model="apiTokenName"
            :horizontal="true"
            :small="true"
            name="API Token Name"
            data-cy="input-token-name"
            internal-platform-input
            placeholder="API Token Name"
            label="API Token Name"
            type="text"
            width="5"
            width-lg="4"
            width-xl="3"
            required
            has-tooltip
          />

          <BaseButtonLoader
            :horizontal="true"
            data-cy="button-create-api-token"
            internal-platform-input
            variant="primary"
            :loading="loading"
            type="submit"
          >
            Create
          </BaseButtonLoader>
        </b-form>
      </validation-observer>

      <b-modal
        id="confirm-copy-token-modal"
        ref="confirm-copy-token-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        title="Copy the new API Token"
        ok-title="Copy Token"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        @ok="closeNewApiTokenModal"
      >
        <div v-if="newApiTokenDetails && newApiTokenDetails.key">
          <h6 class="alert alert-warning mb-4">
            Please copy the new API token, you won't be able to view it later.
          </h6>

          <CodeContainer
            :code-text="newApiTokenDetails.key"
            code-copied-success-message="Copied API Token to clipboard"
            class="mb-4"
          />

          <BaseFormCheckbox
            id="copiedTokenConfirmation"
            v-model="copiedTokenConfirmation"
            name="copiedTokenConfirmation"
            checked-value="true"
            unchecked-value="false"
          >
            <i>Please confirm you've copied the new token by ticking this checkbox.</i>
          </BaseFormCheckbox>
        </div>

        <template #modal-footer>
          <BaseButtonLoader
            type="button"
            :custom-button="false"
            size="sm"
            variant="primary"
            :disabled="!copiedTokenConfirmation"
            @click="closeNewApiTokenModal"
          >
            Done
          </BaseButtonLoader>
        </template>
      </b-modal>

      <b-modal
        id="login-modal"
        ref="login-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        hide-footer
        title="Please login again to verify it's you"
      >
        <Login
          v-if="showLoginModal"
          show-internally
          @success="createApiToken"
        />
      </b-modal>
    </template>
  </Panel>
</template>
<script>
import { mapGetters } from 'vuex'

import Login from '@/views/Login.vue'
import CodeContainer from '@/components/common/CodeContainer.vue'
import ApiTokensAPI from '@/api/api-tokens.api'
import { showErrorMessage } from '@/notification-utils'

export default {
  name: 'CreateApiToken',

  title: 'Create API Token',

  components: {
    Login,
    CodeContainer,
  },

  data() {
    return {
      loading: false,
      showCopyNewTokenModal: false,

      newApiTokenDetails: null,

      showLoginModal: false,

      copiedTokenConfirmation: false,

      apiTokenName: ''
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountId',
      'isUserLoggedInWithinLastXMinutes'
    ]),

    isUserLoggedInWithinLast5Minutes() {
      return this.isUserLoggedInWithinLastXMinutes(5)
    },
  },

  methods: {
    async submitForm() {
      if (this.isUserLoggedInWithinLast5Minutes) {
        return await this.createApiToken()
      }

      this.showLoginModal = true
      this.$bvModal.show('login-modal')
    },

    async createApiToken() {
      this.showLoginModal = false

      this.$bvModal.hide('login-modal')

      try {
        this.loading = true

        const response = await ApiTokensAPI.createApiToken(this.accountId, {
          name: this.apiTokenName
        })

        this.newApiTokenDetails = response.data

        this.$bvModal.show('confirm-copy-token-modal')
      } catch {
        showErrorMessage('Unable to create a new API Token')
      } finally {
        this.loading = false
      }
    },

    closeNewApiTokenModal() {
      this.$bvModal.hide('confirm-copy-token-modal')

      this.$router.push({
        name: 'ListApiTokens'
      })
    }
  },
}
</script>

<style lang="">
  
</style>