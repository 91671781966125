<template>
  <div>
    <!-- <fieldset>
      <legend class="legend-title">
        Basic Details
      </legend>

      <BaseFormToggle
        id="buyer-create-campaign__destination__dynamic-number"
        v-model="isNumberDynamic"
        internal-platform-input
        data-cy="toggle-dynamic-number"
        label="Dynamic Number"
        has-tooltip
      />

      <TargetDestinationForm
        v-if="showTargetDestinationForm"
        :target-details="programmaticTarget"
        @update="updateTargetDetails"
      />
    </fieldset> -->

    <!-- <legend class="legend-title">
      Bid Settings
    </legend>

      :bid-settings="programmaticTarget.bidSettings"
      @update="updateTargetBidSettings"
    /> -->

    <legend class="legend-title">
      Request Settings
    </legend>

    <ProgrammaticTargetRequestSettings
      :programmatic-target="programmaticTarget"
      readonly
      @update="updateTargetDetails"
    />

    <!-- <legend
      v-if="isNumberDynamic"
      class="legend-title"
    >
      Dynamic Number Parsing
    </legend>

    <ProgrammaticTargetParsersList
      v-if="isNumberDynamic"
      :parsers="programmaticTargetCopy.dynamicDialSettingParser"
      @update="updateDynamicNumberParser"
    />


    <legend
      v-if="programmaticTargetCopy.bidSettings.bidType === 'Dynamic'"
      class="legend-title"
    >
      Bid Parsing
    </legend>

    <ProgrammaticTargetParsersList
      v-if="programmaticTargetCopy.bidSettings.bidType === 'Dynamic'"
      :parsers="programmaticTargetCopy.dynamicBidAmountParser"
      @update="updateDynamicBidAmountParser"
    />


    <legend class="legend-title">
      Call Acceptance Parsing
    </legend>

    <ProgrammaticTargetParsersList
      :parsers="programmaticTargetCopy.shouldAcceptCallParser"
      @update="updateDynamicCallAcceptParser"
    />


    <legend
      v-if="programmaticTargetCopy.bidSettings.conversionCallLengthDynamic"
      class="legend-title"
    >
      Dynamic Duration Parsing
    </legend>

    <ProgrammaticTargetParsersList
      v-if="programmaticTargetCopy.bidSettings.conversionCallLengthDynamic"
      :parsers="programmaticTargetCopy.dynamicCallLengthSettingParser"
      @update="updateDynamicDurationParser"
    /> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ProgrammaticTargetRequestSettings from './ProgrammaticTargetRequestSettings.vue'


export default {
  name: 'ProgrammaticTargetForm',

  components: {
    ProgrammaticTargetRequestSettings,
  },

  props: {
    programmaticTarget: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      programmaticTargetCopy: {}
    }
  },

  computed: {
    isNumberDynamic: {
      get() {
        return this.programmaticTarget.isNumberDynamic
      },

      set(value) {
        this.updateDynamicTargetInBuyerCampaign({
          ...this.programmaticTargetCopy,
          isNumberDynamic: value
        })
      }
    },

    showTargetDestinationForm() {
      return !this.isNumberDynamic
    },
  },

  watch: {
    programmaticTarget: {
      immediate: true,
      handler(newValue) {
        this.programmaticTargetCopy = { ...newValue }
      }
    }
  },

  methods: {
    ...mapActions('campaign', [
      'updateDynamicTargetInBuyerCampaign'
    ]),

    updateTargetDetails(target) {
      this.programmaticTargetCopy = target

      this.updateDynamicTargetInBuyerCampaign(this.programmaticTargetCopy)
    },

    updateTargetBidSettings(bidSettings) {
      this.programmaticTargetCopy.bidSettings = bidSettings

      this.updateDynamicTargetInBuyerCampaign(this.programmaticTargetCopy)
    },

    updateDynamicBidAmountParser(parsers) {
      this.programmaticTargetCopy.dynamicBidAmountParser = parsers

      this.updateDynamicTargetInBuyerCampaign(this.programmaticTargetCopy)
    },

    updateDynamicCallAcceptParser(parsers) {
      this.programmaticTargetCopy.shouldAcceptCallParser = parsers

      this.updateDynamicTargetInBuyerCampaign(this.programmaticTargetCopy)
    },

    updateDynamicNumberParser(parsers) {
      this.programmaticTargetCopy.dynamicDialSettingParser = parsers

      this.updateDynamicTargetInBuyerCampaign(this.programmaticTargetCopy)
    },

    updateDynamicDurationParser(parsers) {
      this.programmaticTargetCopy.dynamicCallLengthSettingParser = parsers

      this.updateDynamicTargetInBuyerCampaign(this.programmaticTargetCopy)
    }
  },
}
</script>

<style>

</style>